.novmargin {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.nohmargin {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.novpadding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.nohpadding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.nomargin {
  margin: 0 !important;
}
.nopadding {
  padding: 0 !important;
}
.fullwidth {
  width: 100%;
}
.halfwidth {
  width: 50%;
}
.fullheight {
  height: 100%;
}
.halfheight {
  height: 50%;
}
.notextdecoration {
  text-decoration: none !important;
}
.notexttransform {
  text-transform: none !important;
}
.flex {
  display: flex;
}
.flex-dir-column {
  flex-direction: column;
}
.display-none {
  display: none;
}
.icon-white-onhover:hover {
  color: white !important;
}
.justify-center {
  justify-content: center;
}
.justify-left {
  justify-content: left;
}
.justify-flex-end {
  justify-content: flex-end;
}
.justify-space-between {
  justify-content: space-between;
}
.align-center {
  align-items: center;
}
.align-flex-end {
  align-items: flex-end;
}
.overflow-hidden {
  overflow: hidden;
}
.text-overflow-ellipsis {
  text-overflow: ellipsis;
}
.nowrap {
  white-space: nowrap;
}
.white {
  color: white;
}
.force-white {
  color: white !important;
}
.red {
  color: red;
}
.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.height-fullvh {
  height: 100vh;
}
.min-height-fullvh {
  min-height: 100vh;
}
.min-height-full {
  min-height: 100%;
}
.width-fullvw {
  width: 100vw;
}
.max-width-full {
  max-width: 100%;
}
.max-height-fullvh {
  max-height: 100vh;
}
.text-align-center {
  text-align: center;
}
.text-align-left {
  text-align: left;
}
.text-align-start {
  text-align: start;
}
.pointer {
  cursor: pointer;
}
.metahkg-grey {
  color: #aca9a9;
}
.metahkg-grey-force {
  color: #aca9a9 !important;
}
.metahkg-yellow {
  color: #f5bd1f;
}
.metahkg-yellow-force {
  color: #f5bd1f !important;
}
.user-select-none {
  user-select: none !important;
}
.transparent {
  color: rgba(255, 255, 255, 0);
}
.transparent-force {
  color: rgba(255, 255, 255, 0);
}
.pt5 {
  padding-top: 5px !important;
}
.pt10 {
  padding-top: 10px !important;
}